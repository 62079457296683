@import 'util/cross';

.bubble {
  background: $bubble-color;
  border-radius: 10px;
  color: #fff;
  display: block;
  font-size: 0.9em;
  min-width: 100px;
  opacity: 0;
  padding: 5px 15px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  transition: opacity 1200ms;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  width: auto;
  z-index: 10;

  &.visible {
    opacity: 100;
  }
}

.bubble-select {
  background: $bubble-color;
  border-radius: 10px;
  color: #fff;
  display: block;
  min-width: 150px;
  padding: 5px 10px 10px;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 10;

  h1 {
    font-size: 1.1em;
    margin: 5px 25px 5px 5px;
    text-align: left;
    white-space: nowrap;
  }

  button {
    background: $bubble-select-button-background-color;
    border: 0;
    border-radius: 6px;
    color: $bubble-select-button-text-color;
    cursor: pointer;
    display: block;
    margin: 5px;
    padding: 4px 15px;
    text-align: center;
    text-decoration: none;
    width: calc(100% - 10px);

    &:hover,
    &:active,
    &:focus {
      background: $bubble-select-button-background-hover-color;
      color: $bubble-select-button-text-hover-color;
    }
  }

  .close {
    align-items: center;
    background: rgba(0 0 0 / 25%);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 20px;
    margin: 5px;
    outline: none;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 150ms;
    width: 20px;

    .icon-cross {
      @include cross(10px, #fff, 2px);
    }

    &:hover,
    &:focus {
      transform: rotateZ(90deg);
      background: rgba(0 0 0 / 50%);
    }
  }
}
