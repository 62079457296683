.map-key-select {
  border-radius: 8px;
  bottom: 10px;
  color: $map-key-select-color;
  display: none;
  font-size: 10px;
  font-weight: bold;
  list-style: none;
  max-width: 120px;
  padding: 0 10px;
  position: absolute;
  right: 10px;
  text-align: center;
  text-transform: uppercase;
  z-index: 100;

  @media (max-width: 767px) {
    display: block;
  }
}

.map-key {
  background: url('ui/paper-bg.jpg') center top;
  border-radius: 8px 8px 0 0;
  display: none;
  height: 385px;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  position: relative;
  transition: margin-top 1000ms ease-out;
  width: 900px;
  z-index: 10;

  @media (min-width: 768px) {
    display: block;
  }

  @media (max-width: 920px) {
    width: 730px;
  }

  &.opened {
    margin-top: -385px;

    .key-btn {
      .key-close-btn {
        display: block;
      }

      .key-open-btn {
        display: none;
      }
    }
  }

  li {
    display: block;
    float: left;
    left: 12px;
    list-style: none;
    margin: 5px 0 0;
    padding: 0;
    position: relative;
    top: 10px;
  }

  button {
    background: $map-key-button-background-color;
    border: 0;
    border-radius: 8px;
    box-sizing: content-box;
    color: $map-key-button-text-color;
    cursor: pointer;
    display: block;
    font-size: 1em;
    height: 13px;
    line-height: 1em;
    margin-right: 4px;
    outline: none;
    overflow: hidden;
    padding: 5px 0;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    width: 216px;

    @media (max-width: 920px) {
      font-size: 0.9em;
      width: 174px;
    }

    &:hover,
    &:focus,
    &:active {
      background: $map-key-button-background-hover-color;
      color: $map-key-button-text-hover-color;
    }
  }

  .key-btn {
    float: right;
    left: 0;
    height: 39px;
    margin: -39px 20px 0 0;
    top: 0;
    width: 160px;

    .key-close-btn {
      display: none;
    }

    .key-open-btn {
      display: block;
    }

    button {
      background: url('ui/paper-bg.jpg') center top;
      border-radius: 8px 8px 0 0;
      padding: 0;
      height: 100%;
      width: 100%;

      h1 {
        font-family: marvin, sans-serif;
        font-size: 2em;
        font-style: normal;
        font-weight: 400;
        line-height: 0.9em;
        margin: -5px 0 0;
        text-shadow: 2px 2px 2px #fff;
      }

      &:hover,
      &:focus,
      &:active {
        color: $map-key-button-background-hover-color;
      }
    }
  }
}
