.map-zoom {
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 9;

  @media (min-width: 768px) {
    bottom: 10px;
    left: 10px;
  }

  li {
    float: left;
    list-style: none;
    margin: 0;
    padding: 0 10px 0 0;
    width: 80px;

    @media (max-width: 767px) {
      width: 50px;
    }

    a {
      filter: alpha(opacity=100);
      opacity: 1;

      &.disabled {
        opacity: 0.3;
      }

      img {
        width: 80px;

        @media (max-width: 767px) {
          width: 50px;
        }
      }
    }
  }
}
