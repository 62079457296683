.slide {
  background: url('ui/paper-bg.jpg');
  border-radius: 0 0 15px 15px;
  box-sizing: border-box;
  color: $text-color;
  cursor: default;
  left: 5%;
  margin-top: -750px;
  padding: 15px 15px 10px;
  position: absolute;
  top: 0;
  transition: margin-top 1000ms ease-out;
  width: 90%;
  z-index: 10;

  @media (orientation: portrait) {
    padding: 15px 30px 25px;
  }

  @media (max-width: 480px) and (orientation: portrait) {
    padding: 0 0 25px;
  }

  @media (max-width: 350px) and (orientation: portrait) {
    left: 0;
    width: 100%;
  }

  .text {
    float: left;
    padding-bottom: 10px;
    position: relative;
    overflow: hidden;
    text-shadow: 0 1px 1px #fff;
    width: 33%;

    @media (orientation: portrait) {
      clear: both;
      width: 100%;
    }

    @media (max-width: 480px) and (orientation: portrait) {
      left: 5%;
      width: 90%;
    }

    h1 {
      font-family: futura-pt-condensed, sans-serif;
      font-size: 2em;
      font-weight: 700;
      line-height: 1.2em;
      margin: 0 0 10px;

      @media (max-width: 480px) and (orientation: portrait) {
        margin: 0 0 4px;
      }

      em {
        display: block;
        font-size: 0.6em;
        line-height: 0.9em;

        @media (orientation: portrait) {
          display: inline;
        }
      }
    }

    .description-wrapper {
      position: relative;

      @media (orientation: landscape) {
        height: 0;
        overflow: hidden;
        padding-top: 100%;
      }

      @media (max-height: 413px) and (orientation: landscape) {
        padding-top: 60%;
      }
    }

    .description {
      font-size: 1.2em;
      line-height: 1.1em;
      margin: 0 10px 15px 0;
      overflow: hidden;

      @media (orientation: landscape) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      @media (max-width: 800px) and (orientation: landscape) {
        max-height: 200px;
      }

      @media (max-width: 700px) and (orientation: landscape) {
        max-height: 175px;
      }

      @media (max-width: 600px) and (orientation: landscape) {
        font-size: 1em;
        max-height: 150px;
      }

      @media (max-width: 500px) and (orientation: landscape) {
        max-height: 125px;
      }

      @media (orientation: portrait) {
        margin-bottom: 0;
        max-height: auto;
      }

      @media (max-width: 480px) and (orientation: portrait) {
        font-size: 1em;
        max-height: 100px;
      }

      @media (max-height: 440px) and (max-width: 350px) and (orientation: portrait) {
        max-height: 70px;
      }

      p {
        margin-top: 0;
      }

      .swiper-slide {
        height: auto;
        box-sizing: border-box;
        padding-right: 10px;
      }
    }
  }

  .slideshow {
    float: right;
    position: relative;
    width: 66%;

    @media (orientation: portrait) {
      width: 100%;
    }

    .swiper-slide {
      background: #000;

      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-transform: translateZ(0);
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-backface-visibility: hidden;

      img,
      .jwplayer,
      .krpano {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .gallery-wrapper {
      height: 0;
      overflow: hidden;
      padding-top: 56.25%;
      position: relative;
    }

    .gallery {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .thumbs {
      height: 100px;
      box-sizing: border-box;
      padding: 10px 0;

      @media (max-height: 413px) and (orientation: landscape) {
        display: none;
      }

      .swiper-slide {
        width: 15%;
        height: 100%;
        opacity: 0.4;

        &.swiper-slide-thumb-active {
          opacity: 1;
        }

        .overlay {
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          padding: 10px;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: contain;
          display: block;
          opacity: 0.5;
          background-image: linear-gradient(transparent, #000);
        }
      }
    }
  }

  .locate {
    bottom: 10px;
    color: $text-color;
    font-size: 1.1em;
    font-weight: bold;
    left: 15px;
    position: absolute;
    text-decoration: none;
    text-shadow: 0 1px 1px #fff;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }

  button.close {
    background: url('ui/paper-bg.jpg') center top;
    border: none;
    border-radius: 0 0 12px 12px;
    bottom: -42px;
    color: $map-key-button-text-color;
    height: 42px;
    position: absolute;
    right: calc(50% - 75px);
    width: 150px;
    z-index: 10;

    @media (max-width: 479px) {
      right: calc(50% - 50px);
      width: 100px;
    }

    h1 {
      font-family: marvin, sans-serif;
      font-size: 2em;
      font-style: normal;
      font-weight: 400;
      line-height: 0.9em;
      margin: -5px 0 0;
      text-shadow: 2px 2px 2px #fff;

      @media (max-width: 479px) {
        font-size: 1.5em;
      }
    }

    &:hover,
    &:focus,
    &:active {
      color: $map-key-button-background-hover-color;
    }
  }
}
