$text-color: #92030d;
$slide-border-color: #cb051a;
$bubble-color: #530000;
$bubble-select-button-background-color: #e6cfb0;
$bubble-select-button-text-color: $bubble-color;
$bubble-select-button-background-hover-color: #f2f2f2;
$bubble-select-button-text-hover-color: #333;
$map-key-select-color: #b50a16;
$map-key-button-background-color: #f2f2f2;
$map-key-button-text-color: #b20209;
$map-key-button-background-hover-color: #6e0000;
$map-key-button-text-hover-color: #fff;

// Theme color for swiper
/* stylelint-disable-next-line scss/dollar-variable-pattern */
$themeColor: #fff;

// Reset
@import 'normalize.css';

// Import Swiper and Modules styles
@import 'swiper/scss';
@import 'swiper/scss/free-mode';
@import 'swiper/scss/mousewheel';
@import 'swiper/scss/navigation';
@import 'swiper/scss/scrollbar';
@import 'swiper/scss/thumbs';

@import 'styles/core';
@import 'styles/map';
@import 'styles/loader';
@import 'styles/bubbles';
@import 'styles/zoom';
@import 'styles/key';
@import 'styles/slide';
