.map {
  cursor: move;
  height: 100%;
  margin: 0;
  overflow: hidden;
  position: relative;
  width: 100%;

  .map-content {
    position: absolute;
    z-index: 1;

    #map-image {
      visibility: hidden;
    }

    img {
      -webkit-user-drag: none;
      user-select: none;
    }

    svg.points {
      position: absolute;
      top: 0;
      left: 0;

      g {
        path {
          cursor: pointer;
          opacity: 0;
        }

        &.selected path {
          opacity: 1;
          fill: rgba(255 255 255 / 50%);
        }
      }
    }
  }
}
