@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }

  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.loader {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
  opacity: 1;

  &.hidden {
    opacity: 0;
    transition: opacity 1000ms, height 0ms;
    height: 0;
  }

  .spinner {
    animation: sk-rotateplane 1.2s infinite ease-in-out;
    background-color: $bubble-color;
    height: 60px;
    margin: 25% auto;
    width: 60px;
  }
}
